import { Injectable, afterNextRender } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WindowService {
	private window: Window = {} as Window;

	constructor() {
		afterNextRender(() => (this.window = window));
	}

	open(url: string, target = ''): void {
		this.window.open(url, target);
	}

	getWindowLocation(): Location {
		return this.window?.location;
	}

	getWindowInnerWidth(): number {
		return this.window?.innerWidth;
	}

	getWindowInnerHeight(): number {
		return this.window?.innerHeight;
	}

	scroll(top: number, left: number): void {
		this.window.scroll({ top, left, behavior: 'smooth' });
	}

	scrollTo(left: number, top: number): void {
		this.window.scrollTo(left, top);
	}

	matchMedia(): any {
		return this.window?.matchMedia;
	}
}
